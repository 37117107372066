import "../styles/Navbar.scss";

const Navbar = (props : any) => {
    return (
        <div className={"Navbar"}>
            <div className={"Container"}>
                <a onClick={() => props.SwitchScreen("home")} className={"Logo"}>The Café</a>

                <ul className={"Menu"}>
                    <li className={"MenuItem"}>
                        <a onClick={() => props.SwitchScreen("home")}>Home</a>
                    </li>

                    <li className={"MenuItem"}>
                        <a onClick={() => props.SwitchScreen("about-me")}>About Me</a>
                    </li>

                    <li className={"MenuItem"}>
                        <a onClick={() => props.SwitchScreen("coding-projects")}>Coding Projects</a>
                    </li>

                    <li className={"MenuItem"}>
                        <a onClick={() => props.SwitchScreen("social-media")}>Social Media</a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;

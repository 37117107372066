import "../styles/MainPage.scss";

import Navbar from "./Navbar";
import Home from "./Home";
import AboutMe from "./AboutMe";
import CodingProjects from "./CodingProjects";
import SocialMedia from "./SocialMedia";

import LoopingBackground from "../scripts/LoopingBackground";

import {motion, AnimatePresence} from "framer-motion";

import React from 'react';

function MainPage() {
    const [showNavbar, SetShowNavbar] = React.useState(false);
    const [showHome, SetShowHome] = React.useState(true);
    const [showAboutMe, SetShowAboutMe] = React.useState(false);
    const [showCodingProjects, SetShowCodingProjects] = React.useState(false);
    const [showSocialMedia, SetShowSocialMedia] = React.useState(false);

    const transitionDelay = 0.3;  // In seconds.

    const [disableSwitcher, SetDisableSwitcher] = React.useState(false);

    /**
     * https://stackoverflow.com/questions/58101018/react-calling-a-method-on-load-only-once
     */
    React.useEffect(() => {
         LoopingBackground();
    }, []);  // Need a [] for dependencies to prevent triggering every time there is a re-render.

    function SwitchScreen(screenName : string) {
        // Check if allowed to change screens.
        if (disableSwitcher) return;

        // Disable screen switching.
        SetDisableSwitcher(true);

        DisableComponents();

        (screenName == "home") ? SetShowNavbar(false) : SetShowNavbar(true);

        setTimeout(() => {
            switch (screenName) {
                case "home":
                    SetShowHome(true);
                    break;
                case "about-me":
                    SetShowAboutMe(true);
                    break;
                case "coding-projects":
                    SetShowCodingProjects(true);
                    break;
                case "social-media":
                    SetShowSocialMedia(true);
                    break;
            }

            // Re-enable screen switching.
            SetDisableSwitcher(false);
        }, transitionDelay*1000);
    }

    function DisableComponents() {
        SetShowHome(false);
        SetShowAboutMe(false);
        SetShowCodingProjects(false);
        SetShowSocialMedia(false);
    }

    return (
        <div>
            <AnimatePresence>
                {
                    showNavbar ?
                        <motion.div
                            key={"navbar"}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: transitionDelay, ease: "easeIn"}}
                        >
                            <Navbar SwitchScreen={SwitchScreen}/>
                        </motion.div> : null
                }

                {
                    showHome ?
                        <motion.div
                            key={"home"}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: transitionDelay, ease: "easeIn"}}
                        >
                            <Home SwitchScreen={SwitchScreen}/>
                        </motion.div> : null
                }

                {
                    showAboutMe ?
                        <motion.div
                            key={"about-me"}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: transitionDelay, ease: "easeIn"}}
                        >
                            <AboutMe/>
                        </motion.div> : null
                }

                {
                    showCodingProjects ?
                        <motion.div
                            key={"coding-projects"}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: transitionDelay, ease: "easeIn"}}
                        >
                            <CodingProjects/>
                        </motion.div> : null
                }

                {
                    showSocialMedia ?
                        <motion.div
                            key={"social-media"}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: transitionDelay, ease: "easeIn"}}
                        >
                            <SocialMedia/>
                        </motion.div> : null
                }
            </AnimatePresence>


        </div>
    );
}

export default MainPage;

import "../styles/AboutMe.scss";

function AboutMe() {
    return (
        <div>
            <h1>About me!</h1>
        </div>
    );
}

export default AboutMe;

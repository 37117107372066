import "../styles/CodingProjects.scss";

function CodingProjects() {
    return (
        <div>
            <h1>Coding projects!</h1>
        </div>
    );
}

export default CodingProjects;

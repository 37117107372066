import "../styles/SocialMedia.scss";

function SocialMedia() {
    return (
        <div>
            <h1>Social media!</h1>
        </div>
    );
}

export default SocialMedia;
